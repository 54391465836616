import React from "react";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import "../FrontStyle.css";
import { getToken, getUserLocal } from "../utils/utils";
import { useDispatch } from "react-redux";
import { logoutAction } from "../redux/slice/auth/logoutSlice";

function Header() {
  const user = getUserLocal();
  const user_role = user?.roles[0];
  const dispatch = useDispatch();

  const handleSignOut = () => {
    dispatch(logoutAction(getToken()));
  };
  return (
    <header className="wrap header-fix">
      <section className="wrap top-bar">
        <div className="container">
          <div className="row justify-content-between">
            <div className="d-flex w-auto contact-info mobile-auto mobile-b10">
              <a href="mailto:support@mylanguage.ai">
                <img src="/images/mail.svg" />
                support@shikshamitr.co
              </a>{" "}
              {/* <a href="tel:0000000000">
                <img src="/images/Ph.svg" alt="ph" /> +91 7838701000
              </a> */}
            </div>
            <div className="d-flex w-auto socialmedia mobile-auto ml-auto">
              <ul>
                <li>
                  <a
                    href="#"
                    target="_blank"
                  >
                    <img src="/images/fb.svg" alt="fb" />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    target="_blank"
                  >
                    <img src="/images/insta.png" alt="tw" />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    target="_blank"
                  >
                    <img src="/images/yt.svg" alt="yt" />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    target="_blank"
                  >
                    <img src="/images/in.svg" alt="in" />
                  </a>
                </li>
                {user?.accessToken ? (
                  <>
                    <li>
                      <Link
                        to={
                          user_role === "ROLE_TEACHER"
                            ? "/educator/dashboard"
                            : "/student/dashboard"
                        }
                        className="sign-up"
                      >
                        <img
                          src="images/unknown-user.png"
                          alt="user icon"
                          width="30"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="sign-out" onClick={handleSignOut}>
                        Sign Out
                      </Link>
                    </li>
                  </>
                ) : (
                  <li>
                    <Link to="/sign-up" className="sign-up">
                      Sign In
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <NavBar />
    </header>
  );
}

export default Header;
